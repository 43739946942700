button {
    border: none;
    outline: none;
    background-color: transparent;
}

* {
    font-family: "Lato", sans-serif !important;
    margin: 0;
    padding: 0;
    scrollbar-width: thin !important;
    text-decoration: none;
}

@media (max-width: 768px) {
    * {
        font-size: 13px;
    }
}

@media (max-width: 425px) {
    * {
        font-size: 12px;
    }
}

html,
body {
    color: #000;
}

h1,
h2,
p,
span {
    margin: 0;
}
